import {
	createMemoryHistory,
	createRouter,
	createWebHistory,
} from "vue-router";
import {BASKET_TYPE} from "#app/constants/appContants.js";


export function createAppRouter ($, ssrContext) {

	let homeRedirectUrl = import.meta.env.VITE_HOME_REDIRECT_URL;

	// TODO: add nice 404 and 500 er`ror page
	let routes = [
		{
			name: "index",
			path: "/:restaurantId",
			meta: {
				title: "Expirenza",
				breadCrumb: `restaurantMenu`,
			},
			props: true,
			component: () => import("#app/pages/main/Index.vue"),
		},
		{
			name: "menu-list",
			path: "/:restaurantId/menu",
			meta: {
				title: "Expirenza",
			},
			props: route => ({
				restaurantId: route.params.restaurantId,
				menuId: route.query.menuId,
				dishId: route.query.dishId,
			}),
			component: () => import("#app/pages/main/Menu.vue"),
			children: [
				{
					path: "",
					name: "menu-dish",
					meta: {
						title: "Expirenza",
					},
					props: route => ({
						dishId: route.query.dishId,
					}),
					component: () => import("#app/component/wishlist/DishWishModal.vue"),
				},
			],
		},
		// Delivery routes
		{
			name: "delivery-index",
			path: "/delivery/:restaurantId",
			meta: {
				title: "Expirenza",
				type: BASKET_TYPE.DELIVERY,
			},
			props: true,
			component: () => import("#app/pages/delivery/Index.vue"),
		},
		{
			name: "delivery-menu-list",
			path: "/delivery/:restaurantId/menu",
			meta: {
				title: "Expirenza",
				type: BASKET_TYPE.DELIVERY,
			},
			props: route => ({
				restaurantId: route.params.restaurantId,
				menuId: route.query.menuId,
				dishId: route.query.dishId,
			}),
			component: () => import("#app/pages/delivery/Menu.vue"),
			children: [
				{
					path: "",
					name: "delivery-dish",
					meta: {
						title: "Expirenza",
						type: BASKET_TYPE.DELIVERY,
					},
					props: route => ({
						dishId: route.query.dishId,
					}),
					component: () => import("#app/component/delivery/DishDeliveryModal.vue"),
				},
			],
		},
		{
			name: "delivery-order",
			path: "/delivery/:restaurantId/order",
			meta: {
				title: "Expirenza",
				type: BASKET_TYPE.DELIVERY,
			},
			props: true,
			component: () => import("#app/pages/order/Order.vue"),
		},
		{
			name: "delivery-order-confirm",
			path: "/delivery/:restaurantId/order-confirm",
			meta: {
				title: "Expirenza",
				type: BASKET_TYPE.DELIVERY,
			},
			props: true,
			component: () => import("#app/pages/order/OrderConfirm.vue"),
		},
		{
			name: "delivery-active-orders",
			path: "/delivery/:restaurantId/active-orders",
			meta: {
				title: "Expirenza",
				type: BASKET_TYPE.DELIVERY,
			},
			props: true,
			component: () => import("#app/pages/order/ActiveOrders.vue"),
		},
		{
			name: "order-status",
			path: "/delivery/:restaurantId/active-orders/:orderNumber",
			meta: {
				title: "Expirenza",
			},
			props: true,
			component: () => import("#app/pages/order/OrderStatus.vue"),
		},
		// Order on table routes
		{
			name: "table-order-index",
			path: "/order/:restaurantId",
			meta: {
				title: "Expirenza",
				type: BASKET_TYPE.IN_RESTAURANT,
			},
			props: route => ({tableCode: route.query.tableCode, restaurantId: route.params.restaurantId}),
			component: () => import("#app/pages/delivery/Index.vue"),
		},
		{
			name: "table-order-menu-list",
			path: "/order/:restaurantId/menu",
			meta: {
				title: "Expirenza",
				type: BASKET_TYPE.IN_RESTAURANT,
			},
			props: route => ({
				tableCode: route.query.tableCode,
				restaurantId: route.params.restaurantId,
				menuId: route.query.menuId,
				dishId: route.query.dishId,
			}),
			component: () => import("#app/pages/delivery/Menu.vue"),
			children: [
				{
					path: "",
					name: "table-order-dish",
					meta: {
						title: "Expirenza",
						type: BASKET_TYPE.IN_RESTAURANT,
					},
					props: route => ({
						dishId: route.query.dishId,
					}),
					component: () => import("#app/component/delivery/DishDeliveryModal.vue"),
				},
			],
		},
		{
			name: "table-order-order",
			path: "/order/:restaurantId/order",
			meta: {
				title: "Expirenza",
				type: BASKET_TYPE.IN_RESTAURANT,
			},
			props: route => ({tableCode: route.query.tableCode, restaurantId: route.params.restaurantId}),
			component: () => import("#app/pages/order/Order.vue"),
		},
		{
			name: "table-order-input-tips",
			path: "/order/:restaurantId/tips",
			meta: {
				title: "Expirenza",
				type: BASKET_TYPE.IN_RESTAURANT,
			},
			props: route => ({tableCode: route.query.tableCode, restaurantId: route.params.restaurantId}),
			component: () => import("#app/pages/order/InputTips.vue"),
		},
		{
			name: "table-order-active-orders",
			path: "/order/:restaurantId/active-orders",
			meta: {
				title: "Expirenza",
				type: BASKET_TYPE.IN_RESTAURANT,
			},
			props: route => ({tableCode: route.query.tableCode, restaurantId: route.params.restaurantId}),
			component: () => import("#app/pages/order/ActiveOrders.vue"),
		},
		// legacy routes
		// {
		// 	name: "Payment",
		// 	path: "/:restaurantId/:table",
		// 	meta: {
		// 		title: "Expirenza",
		// 	},
		// 	props: true,
		// 	component: () => import("#app/pages/qr-payment/Index.vue"),
		// },
		// {
		// 	name: "BillsList",
		// 	path: "/:restaurantId/:table/bills",
		// 	meta: {
		// 		title: "Expirenza",
		// 	},
		// 	props: true,
		// 	component: () => import("#app/pages/qr-payment/BillsList.vue"),
		// },
		// {
		// 	name: "Tip",
		// 	path: "/:restaurantId/:table/bills/:billId/",
		// 	meta: {
		// 		title: "Expirenza",
		// 	},
		// 	props: true,
		// 	component: () => import("#app/pages/qr-payment/Tip.vue"),
		// },
		// {
		// 	name: "PaymentStatus",
		// 	path: "/:restaurantId/:table/payment/success/:invoiceId",
		// 	meta: {
		// 		title: "Expirenza",
		// 	},
		// 	props: true,
		// 	component: () => import("#app/pages/qr-payment/PaymentStatus.vue"),
		// },
		{
			name: "wish-list",
			path: "/:restaurantId/wish-list",
			meta: {
				title: "Expirenza",
				breadCrumb: `yourOrder`,
			},
			props: true,
			components: {
				default: () => import("#app/pages/main/WishListPage.vue"),
			},
		},
		{
			name: "eat-list",
			path: "/e/:eatListId/",
			meta: {
				title: "Expirenza",
			},
			props: route => ({eatListId: route.params.eatListId}),
			component: () => import("#app/pages/eatList/eatList.vue"),
		},
		{
			name: "promo-gift",
			path: "/p/:promoGiftId",
			meta: {
				title: "Expirenza - Частування",
			},
			props: route => ({promoGiftId: route.params.promoGiftId}),
			component: () => import("#app/pages/promoGift/promoGift.vue"),
		},
		{
			name: "giveaway",
			path: "/d/:giveawayId",
			meta: {
				title: "Expirenza - Розіграш частувань",
			},
			props: route => ({giveawayId: route.params.giveawayId}),
			component: () => import("#app/pages/giveaway/giveaway.vue"),
		},
		{
			path: "/",
			name: "not-found",
			component: () => import("#app/component/EmptyState.vue"),
		},
		{
			path: "/:pathMatch(.*)*",
			props: route => ({restaurantId: route.params.pathMatch.split("/")[0]}),
			/**
			 * Redirect function.
			 * Doing redirect to Index page, depends on route path first arg.
			 */
			redirect: route => {
				const matchParams = route.params.pathMatch;
				switch (matchParams[0]) {
					case "delivery":
						return {name: "delivery-index", params: {restaurantId: matchParams[1]}};
					case "order":
						return {name: "table-order-index", params: {restaurantId: matchParams[1]}};
					default:
						return {name: "index", params: {restaurantId: matchParams[0]}};
				}
			},
		},
	];

	const router = createRouter({
		history: import.meta.env.SSR ? createMemoryHistory() : createWebHistory(),
		routes,
		scrollBehavior (to, from, savedPosition) {
			if (savedPosition) {
				return savedPosition;
			}
			else {
				return {top: 0};
			}
		},
	});

	router.beforeEach(async (to) => {
		if (to.path === "/" && homeRedirectUrl) {
			window.location = homeRedirectUrl;
		}
	});

	router.beforeResolve(async (to, from, next) => {
		$.pageState.title = [to?.meta?.title?.value || to?.meta?.title || "", to?.meta?.commonTitle?.value || to?.meta?.commonTitle || ""].filter($ => $).join(" | ");
		$.pageState.description = to?.meta?.description ?? "";
		// console.log("router to", to);
		if (to.redirectedFrom) {
			$.redirect = {responseCode: to?.redirectedFrom?.meta?.responseCode || 301, url: to.fullPath};
		}

		next();
	});

	return router;
}
